<template>
  <v-container class="py-6">
    <v-responsive max-width="700" class="mx-auto text-center">
      <div class="text-overline secondary--text mb-3">Account</div>
      <h2 class="text-h3 text-lg-h2 mb-6">Регистрация</h2>
    </v-responsive>

    <v-card class="pa-4 mx-auto" max-width="600">
      <v-card-text>
        <div class="d-flex flex-column flex-md-row">
          Регистрация вашего аккаунта прошла успешно. Администратор рассмотрит вашу заявку и отправит вам уведомление.
        </div>
      </v-card-text>
    </v-card>
    <div class="text-overline text-uppercase mt-3 text-center">
      Входя в систему, вы соглашаетесь с<br/>
      <router-link :to="{ name: 'legal.terms-of-service'}">Условиями использования</router-link>
      &
      <router-link :to="{ name:'legal.privacy-policy' }">Политикой конфиденциальности</router-link>
      сервиса
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'SignupCompletePage',
  head: {
    title: function () {
      return {
        inner: 'Sign up complete'
      }
    }
  }
}
</script>

<style scoped>

</style>
